/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
!! Copy this file to your project's
   sass root. Don't edit the version
   in node_modules.
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
*/

.usa-section--dark .usa-link {
    color: color('base-light');
}
.usa-section--dark .usa-link:hover {
    color: color('base-lightest');
}
.usa-hero {
    background-image: url(/img/hero_cust.png);
}
.usa-section--dark {
    background-color: color("primary-darker");
    color: color("base-lightest");
    .usa-media-block__body {
	margin-left: 2.5em;
    }
    .usa-media-block:hover {
	color: #ffffff;
    }
    a {
	color: color("base-light");
	text-decoration: underline;
    }
    a:hover {
	color: color("primary");
    }
}
.usa-hero__callout {
    box-shadow: 5px 5px 5px color("base-darkest");
}

.usa-link-github {
    background-image: url(/img/github-alt.png);
}

.usa-social_link {
    @include at-media-max($theme-header-min-width) {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto 2.5rem;
	display: inline-block;
	height: 3.5rem;
	width: 3.5rem;
	position: relative;
	text-align: center;
	
    }
    @include at-media($theme-header-min-width) {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto 3rem;
	display: inline-block;
	height: 4.4rem;
	width: 4.4rem;
	position: relative;
	text-align: center;
    }
    .usa-social_link__text {
	display: none;
    }
    
}
